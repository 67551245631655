body {
  margin: 0;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.max-600 {
  max-width: 600px;
}
.max-800 {
  max-width: 800px;
}
.pill-btn {
  display: inline-block;
  border: 1px solid #fff;
  padding: 0rem 0.75rem;
  border-radius: 40rem;
  text-decoration: none !important;
  transition: all 250ms ease;
}
.pill-btn:hover {
  color: #434144 !important;
  background-color: #fff;
}

.MuiOutlinedInput-root {
  border-radius: 0px !important;
}
/* Dots Loader */
.spinner {
  text-align: center;
}

.spinner > div {
  width: 12px;
  height: 12px;
  background-color: #78909c;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.big-spinner {
}
.big-spinner.spinner > div {
  width: 20px;
  height: 20px;
}
.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* Overrides */
.col-form-label {
}
.MuiTypography-body1 {
  font-size: 0.875rem !important;
}
div[role='tabpanel'] {
  width: 100%;
}
button:focus {
  outline: none !important;
}
